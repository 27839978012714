import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            auth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
        meta: {
            auth: false
        }
    },
    {
        path: '/homePage',
        name: 'homePage',
        component: () => import(/* webpackChunkName: "about" */ '../views/article/HomePageView.vue'),
        meta: {
            auth: true
        }
    },
    {
        path: '/articleList',
        name: 'articleList',
        component: () => import(/* webpackChunkName: "about" */ '../views/article/ArticleListView.vue'),
        meta: {
            auth: true
        }
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
        meta: {
            auth: true
        }
    }
]

const router = new VueRouter({
    routes
})
// 路由守卫
router.beforeEach((to, from, next) => {
    // to要跳转到的路径
    // from从哪个路径来
    // next往下执行的回调
    // 在localStorage中获取token
    let token = localStorage.getItem('userName')
    // 判断该页面是否需要登录
    if (to.meta.auth) {
        // 如果token存在直接跳转
        if (token) {
            next()
        } else {
            // 否则跳转到login登录页面
            next({
                path: '/login',
                // 跳转时传递参数到登录页面，以便登录后可以跳转到对应页面
                // query: {
                //     redirect: to.fullPath
                // }
            })
        }
    } else {
        if (to.name === 'login' && token) {
            // 如果不需要登录，则直接跳转到对应页面
            next('/')
        } else {
            next()
        }
    }
})
export default router
