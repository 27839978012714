<template>
  <div>首页</div>
</template>

<script>

export default {
  name: 'HomeView'
}
</script>
<style scoped>

</style>
