<template>
  <div id="app">
    <el-container v-if="isLogin" style="height: 100vh">
      <el-aside style="background-color: rgb(238, 241, 246);width:200px;height: 100vh">
        <el-menu default-active="1" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :collapse="isCollapse">
          <el-menu-item index="1">
            <i class="el-icon-location"></i>
            <span slot="title">首页</span>
          </el-menu-item>
          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span slot="title">文章管理</span>
            </template>
            <router-link to="/homePage" class="a-text-decoration">
              <el-menu-item index="2-1">前台首页</el-menu-item>
            </router-link>
            <router-link to="/articleList" class="a-text-decoration">
              <el-menu-item index="2-2">文章列表</el-menu-item>
            </router-link>
          </el-submenu>
          <el-menu-item index="3">
            <i class="el-icon-document"></i>
            <span slot="title">设置</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header style="text-align: right; font-size: 12px">
          <el-dropdown>
            <i class="el-icon-setting" style="margin-right: 15px"></i>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>查看</el-dropdown-item>
              <el-dropdown-item>新增</el-dropdown-item>
              <el-dropdown-item>删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <span>王小虎</span>
        </el-header>
        <el-main>
          <router-view/>
        </el-main>
      </el-container>
    </el-container>
    <router-view v-else/>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isCollapse: false,
      isLogin: false
    };
  },
  mounted() {
    const userName = localStorage.getItem('userName')
    if (userName) {
      this.isLogin = true
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  }
}
</script>

<style>
html, body, * {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.font-weight {
  font-weight: bold;
}

.display-flex-align-items-center {
  display: flex;
  align-items: center;
}

.display-flex-justify-content-space-between {
  display: flex;
  justify-content: space-between;
}

.margin-left-10 {
  margin-left: 10px;
}

.a-text-decoration {
  text-decoration: none;
}

textarea.el-textarea__inner {
  resize: none;
}
</style>
<style scoped>
.el-header {
  background-color: #B3C0D1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}
</style>
